import Vue from 'vue'


import {
  Select,
  Option,
  Breadcrumb,
  BreadcrumbItem,
  Progress
} from 'element-ui'
// import Element from 'element-ui'
// import locale from 'element-ui/lib/locale/lang/en'

// Vue.use(Element, { locale })

Vue.use(Select)
Vue.use(Option)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Progress)
