var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{class:['header', {
  'header_bg':_vm.isSwitchLogo,
  'isWhite':_vm.isWhite
}]},[_c('div',{staticClass:"header_type_erea"},[_c('a',{staticClass:"header_logo",attrs:{"href":"/"}},[_c('img',{attrs:{"src":_vm.isSwitchLogo || _vm.isWhite ? require('../assets/img/logo_red.svg') : require('../assets/img/logo_white.svg'),"alt":""}}),_vm._v(" "),_c('h1',[_vm._v("沛鸿官网")])]),_vm._v(" "),_c('ul',{class:['header_nav', {
        'nav_color':!_vm.isSwitchLogo
      }]},[_vm._l((_vm.navList),function(item){return _c('li',{key:item.id,on:{"mouseleave":function($event){return _vm.mouseLeave(item,$event)},"mouseenter":function($event){return _vm.mouseEnter(item,$event)},"click":function($event){return _vm.navClick(item, $event)}}},[_c('nuxt-link',{class:[{
        'nav_active': item.selected
      }],attrs:{"to":item.link}},[_vm._v(_vm._s(item.title))])],1)}),_vm._v(" "),_c('li',{staticClass:"header_language"},[_c('div',[_c('img',{staticClass:"earth",attrs:{"src":_vm.isSwitchLogo || _vm.isWhite ? require('../assets/img/earth_black_icon.png'): require('../assets/img/earth_icon.png'),"alt":""}})]),_vm._v(" "),_c('div',[_c('Select',{attrs:{"default":"简体中文","options":_vm.options},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}})],1),_vm._v(" "),_c('div',{class:['language_arrow','el-icon-arrow-down', {
          'is-white': !_vm.isSwitchLogo
        }]})]),_vm._v(" "),_c('li',{ref:"sliding",staticClass:"sliding"})],2)]),_vm._v(" "),_c('transition',{attrs:{"name":"height"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFloat),expression:"showFloat"}],staticClass:"float-container",on:{"mouseenter":function($event){return _vm.floatEnter(3)},"mouseleave":function($event){return _vm.floatLeave(3)}}},[_c('div',{staticClass:"float-container_type_area"},[_c('ul',{staticClass:"float-img"},_vm._l((_vm.floatImgInfo),function(item){return _c('li',{key:item.id,staticClass:"float-img-item",on:{"click":function($event){return _vm.floatClick(item)}}},[_c('img',{attrs:{"src":item.img,"alt":item.title}}),_vm._v(" "),_c('p',{domProps:{"textContent":_vm._s(item.title)}})])}),0),_vm._v(" "),_c('ul',{staticClass:"float-list"},_vm._l((_vm.floatList),function(item){return _c('li',{key:item.id,on:{"click":function($event){return _vm.floatClick(item)}}},[_vm._v(_vm._s(item.title))])}),0)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }