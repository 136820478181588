//
//
//
//
//
//
//
//
//

import Header from './header.vue'
import Footer from './footer.vue'
export default {
  data(){
    return{
      isSwitchLogo: true,
      isShowFooter: true
    }
  },
  provide(){
    return {
      showFooter: this.showFooter,
      switchHeaderStatus: this.switchHeaderStatus
    }
  },
  methods:{
    // 切换header背景色以及logo颜色
    switchHeaderStatus(param){
      this.isSwitchLogo = param

    },
    // 是否显示footer，true显示， false隐藏， 默认true
    showFooter(param){
      this.isShowFooter = param
    }
  },
  components:{
    Header,
    Footer
  }
}
