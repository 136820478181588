//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      tapList: [
        {
          id: 1,
          img: require("../assets/img/area_shenzhen.png"),
          title: "深圳",
          label: "SHENZHEN",
          selected: true
        },
        {
          id: 2,
          img: require("../assets/img/area_huizhou.png"),
          title: "惠州",
          label: "HUIZHOU",
          selected: false
        },
        {
          id: 3,
          img: require("../assets/img/area_taibei.png"),
          title: "台北",
          label: "TAIPEI",
          selected: false
        },
        {
          id: 4,
          img: require("../assets/img/area_us.png"),
          title: "美国",
          label: "US",
          selected: false
        },
        {
          id: 5,
          img: require("../assets/img/area_xianggang.png"),
          title: "香港",
          label: "HK",
          selected: false
        },
      ],
    };
  },
  methods: {
    projectClick(){
      this.$router.push('/blocProduct')
    }
  }
};
