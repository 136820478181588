//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    options: { // option选项
      type: Array,
      default: []
    },
    default: { // 默认值
      type: String,
      default:'简体中文'
    }
  },
  data(){
      return {
        value: ''
      }
  },
  mounted(){
    this.value = this.default
  },
  methods: {
    input(e){
      this.$emit('input', e)
      this.value = '简体中文'
    }
  }
}
